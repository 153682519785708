<template>
  <div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="from.username"
        name="问诊人姓名"
        label="问诊人姓名"
        placeholder="问诊人姓名"
        :rules="[{ required: true, message: '请填写问诊人姓名' }]"
      />
      <van-field name="radio" label="性别">
        <template #input>
          <van-radio-group v-model="from.sex" direction="horizontal">
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="from.orld"
        name="年龄"
        type="digit"
        label="年龄"
        placeholder="年龄"
        :rules="[{ required: true, message: '请填写年龄' }]"
      />
      <van-field name="radio" label="是否在该院就诊过">
        <template #input>
          <van-radio-group v-model="from.sex" direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="message"
        rows="6"
        autosize
        label="病情描述"
        type="textarea"
        maxlength="500"
        placeholder="病情描述"
        show-word-limit
      />
      <div class="upload">
        <p><span>图片</span>(最多9张图片)</p>
        <p>传检查报告或者患处图片，助医生更全面了解病情照片仅自己和医生可见</p>
        <van-uploader v-model="fileList" multiple />
      </div>
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"> 提交 </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      from: {
        username: "",
        sex: "",
        orld: "",
      },
      fileList:[],
    };
  },
  methods: {
    onSubmit() {

      Toast('提示内容');
    },
  },
};
</script>
<style lang="less" scoped>
.upload {
  text-align: left;
  color: #c1c4c6;
  padding: 16px;
  p {
    color: #c1c4c6;
    margin-bottom: 10px;
    span {
      color: #000;
      margin-right: 16px;
    }
  }
}
</style>
